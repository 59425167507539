import React, { useEffect, useState } from "react";
import { Container, Row,Col, Table ,Form} from "react-bootstrap";
import BannerImage from "../assets/images/ido-banner-main.png"
import SecondImage from "../assets/images/second-section.png"
import SecondBackground from "../assets/images/second-background.png";
import { Link,useNavigate } from "react-router-dom";
import { litePad_crowdsale_addr,litePad_addr , busd_addr } from "../contract/addresses";
//import BUSD from '../contract/BUSD.json';
import LitePadCrowdsale from '../contract/LitePadCrowdsale.json'
import LitePad from '../contract/LitePad.json'
import axios from 'axios';
import { useWeb3React } from "@web3-react/core";
import Web3Modal from 'web3modal'
import { ethers } from 'ethers'
import VectorLogo from "../assets/images/ltp.png"
import Particle from "../components/Particle";




function PublicSale(props){
    const {
        connector,
        library,
        account,
        chainId,
        activate,
        deactivate,
        active,
        errorWeb3Modal
    } = useWeb3React();

    const loadProvider = async () => {
        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);
            return provider.getSigner();
          } catch (e) {
          }
      };
    
    const buy = async () => {
        try{
            console.log(price)
            let signer = await loadProvider()


            let test = new ethers.Contract(busd_addr, LitePad, signer)
            let dec = await test.decimals()
            let lite  = new ethers.Contract(litePad_addr, LitePad, signer)
           // console.log(dec)
            let _value = await ethers.utils.parseUnits(price.toString() , dec )
            let approve = await test.approve(litePad_crowdsale_addr, _value)
            let approveTx = await approve.wait()
            let check = await test.allowance(account, litePad_crowdsale_addr)
            let balance = await lite.balanceOf(litePad_crowdsale_addr)
            console.log(balance.toString())
            console.log(check.toString())
            if(check.toString() != '0'){
                let crowdsaleCon = new ethers.Contract(litePad_crowdsale_addr, LitePadCrowdsale, signer)
                let buy = await crowdsaleCon.buyTokens()
                await buy.wait()
            }
        }catch(error){
            console.log("buy button",error)
        }
    }

      const [qty,setQty] = useState(1);
      const [price,setPrice] = useState(0.02);
  
      const increase = () => {
          
          if(qty < 100){
              setQty(qty + 1)
          }
      };
  
      const decrease = () => {
          if(qty > 1){
              setQty(qty - 1)
          }
      };
      
    useEffect(() => {
        (async () => {
            if (account) {
                try {

                } catch (error) {
                }
            }
        })()
    }, [account]);
    useEffect(()=>{
        setPrice(0.02 * qty)
    },[qty])
    return (
        <>
        <Particle />

        <div className="text">
        {props.header}
        <div className="position-relative">
            {/* <img src={require('../assets/images/banner-background.png').default} className="banner-background"/> */}
            <div className="banner-section">
                <Container>
                    
                <Row style={{display:"flex", alignItems:"center" , justifyContent:"center"}}>
                    <Col lg={6} sm={12} md={5} >
                        <img src={VectorLogo} className="pub-sale-image"/>
                    </Col>

                    <Col lg={6} sm={12} md={5} style={{textAlign:"center"  }} className="top_div"> 
                    <div className="sale-section">
                        <h2>LitePad Token</h2>
                        <p className="token-description">1 BUSD = 50 LitePad</p>
                        <h2>{parseFloat(price.toFixed(3))} BUSD</h2>
                        <div className="min-max">
                        <div class="increament">
                            <div class="value-button decrease" id="decrease" value="Decrease Value" onClick={(e)=>decrease()}>-</div>
                            <input type="number" id="room-number" value={qty} min="1" max="20" class="number" onChange={(e)=>setQty(e.target.value)}/>
                            <div class="value-button increase" id="increase" value="Increase Value" onClick={(e)=>increase()}>+</div>
                        </div>
                        </div>
                        <div className="pub-sale-footer">
                        <button className="btn-custom secondary-btn" style={{backgroundColor:"#0048a8"}} onClick={buy}>Buy LitePad Token</button>
                        </div>
                    </div>
                    </Col>
                   
                   
                </Row>
                </Container>
            </div>
        </div>

        {props.footer}
        </div>
        
        </>
    );
}
export default PublicSale;