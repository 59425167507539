import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import "../assets/css/style.css"


const Footer = () => {

    const prevent = (e) =>{
        e.preventDefault()
    }
  return (
    <footer className='mt-5'>
        <Container>
            <Row>
                <Col lg={6} md={6}>
                <p className='copyright'>Litepad Token © {new Date().getFullYear()}</p>
                </Col>

                <Col lg={6} md={6}>

                    <ul className='social'>
                        <li>
                            <a href='https://twitter.com/litepadt'><i class="fa-brands fa-twitter"></i></a>
                        </li>
                        <li>
                            <a href='#' onClick={prevent}><i class="fa-brands fa-telegram"></i></a>
                        </li>
                    </ul>

                </Col>
            </Row>

        </Container>

    </footer>
  )
}

export default Footer